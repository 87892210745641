<template>
    <div class="ticket" >
        <div class="nav">
            <van-row>
                <van-col span="4">
                    <div @click="logout">
                        <van-icon name="arrow-left" color="#fff" />
                    </div>
                </van-col>
                <van-col span="16">
                    <text style="color:#fff">{{lang.activity_details}}</text>
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn"/>
                    </div>
                </van-col>
            </van-row>
        </div>
        <div class="main" v-if="activity && activity.info">
            <van-row >
                <van-col offset="1" span="22">
                    <div :style="`background-image:url(${common.getRes('ticket_bg.png')});background-size:100%;background-repeat:y-repeat;`">
                        <van-row>
                            <van-col offset="2" span="20">
                                <div class="h1 row bottom-line">
                                     {{common.changeLanguage(activity.info.en_name,activity.info.cn_name,lang.language)}}
                                </div>
                                <div class="row bottom-line">
                                    <van-row>
                                        <van-col span="13">
                                            <div class="h3">
                                             {{activity.info.start_date}} {{activity.info.start_time.substr(0,5)}} - {{activity.info.end_time.substr(0,5)}}
                                            </div>
                                            <div class="h3 nowrap ellipsis">
                                               {{common.changeLanguage(activity.info.en_location,activity.info.cn_location,lang.language)}}
                                            </div>
                                        </van-col>
                                        <van-col offset="1" span="10">
                                            <img class='main-img' :src="activity.info.image || Config.defaultMainImg" />
                                        </van-col>
                                    </van-row>
                                </div>
                                <div class="row bottom-line" >
				                    <!--<van-row align="center">
                                            <van-col offset="1" span="22">
                                                <div class="h1">
                                                {{common.changeLanguage("Successfully Signed Up! Welcome to the ISA International Education Group Future Leaders Cultivation Open Day！","您已成功签到！欢迎来到爱莎国际教育集团未来领袖培养开放日!",lang.language)}}
                                                </div>
                                            </van-col>
                                        </van-row>-->
				                    <div class="taggle-btn taggle-btn-on" v-show="hasLottery()">
                                        {{lang.lucky_draw_no}}
                                        <div class="btn-on-line"></div>
                                    </div>

                                    <van-row align="center" v-show="hasLottery()">
                                        <van-col span="24">
                                            <div class="row">
                                                <template v-if="userInfo.win_item == null">
                                                    <div class="card" >
                                                        <div class="card-str" >{{lang.lucky_draw_no}}：</div>
                                                        <div class="card-number">{{userInfo.code.replace(/(.{3})/g, "$1 ")}}</div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="card win" >
                                                        <div class="card-str golden" >{{lang.lucky_draw_no}}：</div>
                                                        <div class="card-number golden" >{{userInfo.code.replace(/(.{3})/g, "$1 ")}}</div>
                                                        <div class="win-item">
                                                            {{common.changeLanguage(userInfo.win_item.en_title,userInfo.win_item.cn_title,lang.language)}}
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </van-col>
                                    </van-row>
                                    <van-row align="center">
                                        <van-col offset="6" span="12">
                                            <div 
                                                class="activity_btn"
                                                :class="!wait?'on':'off'"
                                                @click="refreshTicket">{{!wait ? lang.refresh : `${lang.waiting}(${waitSecond})`}}</div>
                                        </van-col>
                                    </van-row>
                                <van-row>
                                    <van-col :offset="hasVote() ? 3 : 8" span="8" v-show="hasBlessing()">
                                        <div class="activity_btn" @click="goBlessing">{{lang.leave_comments}}</div>
                                    </van-col>
                                    <van-col :offset="hasBlessing() ? 2 : 8" span="8" v-show="hasVote()">
                                        <div class="activity_btn"  @click="goVote" >{{lang.program_voting}}</div>
                                    </van-col>
                                </van-row>
                                <van-row>
                                    <van-col offset="8" span="8">
                                    <a href="https://guide.isagzth.com/live/" class="activity_btn">{{lang.Live_Photos}}</a>	
                                    </van-col>
                                </van-row>
                                </div>

                               <div class="row bottom-line" >
                                    <span class="h1">{{common.changeLanguage(activity.info.en_location,activity.info.cn_location,lang.language)}}</span>
				    <!--<span class="Live_Photos"><a href="https://guide.isagzth.com/live/" >{{lang.Live_Photos}}</a></span>-->
                                </div>

                                <div class="row bottom-line">
                                    <van-row>
                                        <van-col span="24">
                                            <div class="h3">{{lang.order_time}}：{{userInfo.ticket.created_at.substr(0,10) +" "+userInfo.ticket.created_at.substr(11,8)}}</div>
                                        </van-col>
                                        <van-col span="24">
                                            <div class="h3">{{lang.order_no}}：{{userInfo.ticket.outer_id}}</div>
                                        </van-col>
                                        <van-col span="24">
                                            <div class="h3">{{lang.participant}}：{{userInfo.ticket.name}}</div>
                                        </van-col>
                                        <van-col span="24">
                                            <div class="h3">{{lang.phone_no}}：{{userInfo.ticket.phone}}</div>
                                        </van-col>
                                    </van-row>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </van-col>
            </van-row>
            <div style="width:100%;height:50px;">
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs , onMounted } from 'vue';
import Common from "../library/Common";
import Config from "../library/Config";
import myRequest from '../library/MyRequest';
import { useRouter } from 'vue-router';
import { Dialog } from 'vant';

export default {
    name: 'ticket',
    setup(){
        const router = useRouter();
        const common = Common;
        let state = reactive({
            lang:{
                langBtn:"",
                exit_login:"",
                cancel:"",
                determine:"",
                lucky_draw_no:"",
                order_time:"",
                order_no:"",
                participant:"",
                phone_no:"",
                leave_comments:"",
                program_voting:"",
                activity_details:"",
                language:"",
		        Live_Photos:"",
                refresh:"",
                waiting:"",
            },
            activity:null,
            wait:false,
            waitSecond:60,
            userInfo:common.getUserInfo(),
        });

        if(state.userInfo == null){
            var activity_id = common.getCurrentActivityId()
            common.logout(activity_id);
            router.push({name: 'login',query:{id:activity_id}});
        }


        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.language = common.getCurrentLanguage();
            state.lang.langBtn = common.getLanguageBtn();
        }

        const logout = function(){
            Dialog.confirm({
                title:state.lang.exit_login,
                confirmButtonText:state.lang.determine,
                cancelButtonText:state.lang.cancel,
            }).then(() => {
                var activity_id = state.userInfo.ticket.activity;
                common.logout(activity_id);
                router.push({name: 'login',query:{id:activity_id}});
            });
        }

        const getFromCode =async()=>{
            var res = await myRequest.get(`activity/program/from-code/?activity_id=${state.userInfo.ticket.activity}`);
            if(res.status == 200){
                state.activity = res.data;
                return true;
            }
            return false;
        }

        const getProgram = async()=>{
            var activityRes = await myRequest.get(`activity/activity/${state.userInfo.ticket.activity}/`);
            if(activityRes.status == 200){
                state.activity.info = activityRes.data;
            }
        }

        const hasLottery = function(){
            return state.activity && state.activity.lottery.has;
        } 
        
        const hasVote = function(){
            return state.activity && state.activity.voting.has;
        }

        const hasBlessing = function(){
            return state.activity && state.activity.blessing.has;
        }

        const goBlessing = function(){
            router.push({name:"blessing",params:{id:state.activity.blessing.id}});
        }

        const goVote = async()=>{
            if(await getFromCode()){
                if(hasVote()){
                    router.push({name:"vote",params:{id:state.activity.voting.id}});
                }
            }
        }

        const refreshTicket = async()=>{
            if(state.wait) return ;
            state.wait = true;
            state.waitSecond = 60;
            let interval = setInterval(()=>{
                state.waitSecond--;
                if(state.waitSecond < 0){
                    clearInterval(interval);
                    state.wait = false;
                }
            },1000);

            var send = {
                activity_id:state.userInfo.ticket.activity,
                phone:state.userInfo.loginInfo.phone,
                name:state.userInfo.loginInfo.name,
            }
            var res = await myRequest.post("activity/free-ticket/",send);
            if(res.status == 200){
                var userInfo = res.data.code;
                userInfo.loginInfo = {
                    phone:send.phone,
                    name:send.name,
                }
                common.setUserInfo(userInfo,state.userInfo.ticket.activity);
                state.userInfo = userInfo;
            }
        }

        onMounted(async()=>{
            state.lang.language = common.getCurrentLanguage();
            if(await getFromCode()){
                getProgram();
            }
        })
        
        return {
            ...toRefs(state),
            common,
            toggleLanguage,
            logout,
            Config,
            goBlessing,
            goVote,
            hasVote,
            hasLottery,
            hasBlessing,
            refreshTicket
        };
    }
}
</script>

<style scoped>
.ticket{
    width: 100%;
    height: 100%;
    background-color: #2A3F54;
    overflow-y:hidden;
}
.nav{
    background-color: #2A3F54;
}

.on{
    background-color: #2A3F54;
    color: #fff;
}

.off{
    background-color: #466b8f;
    color: #fff;
}
.main-img{
  width:100%;
  border-radius:10px;
}

.main{
    height:calc(100% - 60px);
    overflow-y:auto;
    text-align:left;
}

.taggle-btn{
  display: inline-block;
  color:#999;
  font-size: 24px;
  margin:0 10px;
  text-align: center;
}
.taggle-btn-on{
  font-size: 34px;
  font-weight: 600;
  color:black;
}
.btn-on-line{
  width: 30%;
  position: relative;
  left:35%;
  top:5px;
  border-bottom: 5px solid #FF9839;
}

.win-item{
  width: 100%;
  text-align: center;
  font-size: 28px;
  color:#D3AC85;
}


.card{
  border-radius: 20px;
  border:1px solid #979797;
  padding:10px 20px 20px 20px;
  margin-bottom: 10px;
}
.card-number, .card-str{
  display:inline-block;
}
.card-str{
  color:#999;
  font-size: 24px;
}
.card-number{
  color:#000;
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
}

.golden{
  color:#D3AC85;
}
.win{
  background-color: rgb(42, 62, 83);
  border: #D3AC85 2px solid;
}

.activity_btn{
  display: inline-block;
  border:2px solid #000;
  border-radius: 100px;
  font-size: 24px;
  text-align: center;
  padding:20px 0px;
  margin:0;
  width: 100%;
}
.Live_Photos{
  font-size: 24px;
  margin:0 10px;
}
</style>
